import * as firebase from 'firebase';
//import firestore from 'firebase/firestore';
import "firebase/auth";
//const settings = {timestampsInSnapshots: true};

/*const config = {
	apiKey: "AIzaSyCIFJ8TQu1n7NqTQ_hQubJLzSyePGatEww",
	authDomain: "verdant-emitter-213909.firebaseapp.com",
	databaseURL: "https://verdant-emitter-213909.firebaseio.com",
	projectId: "verdant-emitter-213909",
	storageBucket: "verdant-emitter-213909.appspot.com",
	messagingSenderId: "820291622767"
};*/
const config = {
  apiKey: "AIzaSyDbL9wid02X48uujQ3Q5RJjikEnyyVNHtU",
  authDomain: "expectmycall.firebaseapp.com",
  databaseURL: "https://expectmycall.firebaseio.com",
  projectId: "expectmycall",
  storageBucket: "expectmycall.appspot.com",
  messagingSenderId: "163487637176",
  appId: "1:163487637176:web:8528e69c9b8d0151dc5e4d",
};
firebase.initializeApp(config);

firebase.firestore().settings({});

export default firebase;