import axios from 'axios';
var CryptoJS = require("crypto-js");

class ApiService {

    
    generateRandonNumber(length = 10) {
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    /*Externale API*/
    getExternalAPI(urlSegment) {
        return axios.get(urlSegment);
    }

    /*Get API*/
    getAPI(urlSegment) {
        return axios.get(urlSegment);
    }
    /*Get AccessToken*/
    getAccessToken() {
        let accessToken = localStorage.getItem("accessToken");        
        if(accessToken === '' || accessToken === null)
          return "";
        else
          return CryptoJS.AES.decrypt(localStorage.getItem("accessToken"), 'EXPECTMYCALLENCRYPTION@12345').toString(CryptoJS.enc.Utf8)
    }

    /*Get Organization Subscribe*/
    getIsSubscribe() {
        let isSubscribed = localStorage.getItem("isSubscribed");        
        if(isSubscribed === '' || isSubscribed === null)
          return "";
        else
          return (CryptoJS.AES.decrypt(localStorage.getItem("isSubscribed"), 'EXPECTMYCALLENCRYPTION@12345').toString(CryptoJS.enc.Utf8) === "true") ? true : false;
    }
    setIsSubscribe(isSubscribe = "false") {
        isSubscribe = isSubscribe.toString();
        localStorage.setItem( 'isSubscribed', CryptoJS.AES.encrypt(isSubscribe, 'EXPECTMYCALLENCRYPTION@12345').toString());
    }

    getLocalStorageValue(key) {
        let data = localStorage.getItem(key);        
        if(data === '' || data === null)
          return "";
        else{
          let decyptdata =  CryptoJS.AES.decrypt(localStorage.getItem(key), 'EXPECTMYCALLENCRYPTION@12345').toString(CryptoJS.enc.Utf8);
          return decyptdata ? JSON.parse(decyptdata) : decyptdata;
        }
    }
    setLocalStorageValue(key, value) {
        let data = JSON.stringify(value);
        localStorage.setItem( key, CryptoJS.AES.encrypt(data, 'EXPECTMYCALLENCRYPTION@12345').toString());
    }

    decryptStringData(cipherText) {
        var reb64 = CryptoJS.enc.Hex.parse(cipherText);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, 'EXPECTMYCALLENCRYPTION@12345');
        var plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
        
    }
    encryptStringData(plainText) {
        var b64 = CryptoJS.AES.encrypt(plainText, 'EXPECTMYCALLENCRYPTION@12345').toString();
        var e64 = CryptoJS.enc.Base64.parse(b64);
        var eHex = e64.toString(CryptoJS.enc.Hex);
        return eHex;
    }

    decryptCipherData(cipherText) {
        let plainText = CryptoJS.AES.decrypt(cipherText, 'EXPECTMYCALLENCRYPTION@12345');
        
        plainText = plainText.toString(CryptoJS.enc.Utf8)
        return plainText;
        
    }
    encryptCipherData(plainText) {
        var b64 = CryptoJS.AES.encrypt(plainText, 'EXPECTMYCALLENCRYPTION@12345').toString();
        /*var e64 = CryptoJS.enc.Base64.parse(b64);
        var eHex = e64.toString(CryptoJS.enc.Hex);*/
        return b64;
    }

    encryptAffiliateCode(affilateCode) {
        if(affilateCode !== ""){
            let finalString = "";
            for(let i =0; i < affilateCode.length; i++){
                finalString += affilateCode[i]+this.generateRandonNumber(4);
            }
            return finalString;
        }
        else
            return "";
    }
    decryptAffiliateCode(encryptedCode) {
        if(encryptedCode !== ""){
            let finalString = "";
            for(let i =0; i < encryptedCode.length; i += 5){
                
                finalString += encryptedCode[i];
            }
            return finalString;
        }
        else
            return "";
    }

    getAuthId() {
        let isSubscribed = localStorage.getItem("authId");        
        if(isSubscribed === '' || isSubscribed === null)
          return "";
        else
          return CryptoJS.AES.decrypt(localStorage.getItem("authId"), 'EXPECTMYCALLENCRYPTION@12345').toString(CryptoJS.enc.Utf8);
    }
    /*Get API With Authentication header */
    
    /* Check user logged in or not */
    getAuth(){
        let accessToken = localStorage.getItem("accessToken");        
        if(accessToken === '' || accessToken === null)
          return false;
        else
          return true;
    }
    
}

export default new ApiService();
