import React, { Component, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
/*Common Service*/
import commonService from './core/services/commonService';
import { AuthProvider, AuthContext } from "./AuthChecker";
import firebase from './core/Firebase/Firebase';

const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const OrganizationLayout = React.lazy(() => import('./containers/OrganizationLayout'));
const FrontEndLayout = React.lazy(() => import('./containers/FrontEndLayout/FrontEndLayout'));


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
var userInfoOnStateChange = null;
class App extends Component {
  constructor(props) {
    super(props)
    this.userRef = null;
    this.state = {
      loading: true
    }

  }
  componentDidMount(){
    firebase.auth().onAuthStateChanged((user) => {
      userInfoOnStateChange = user;
      if(user){
        
      }

      this.setState({loading: false});
    })
  }
  render() {
    if(this.state.loading)
      return (    
      <Router >
          <React.Suspense fallback={loading()}></React.Suspense></Router>);

    return (
    <AuthProvider>
      <Router >
          <React.Suspense fallback={loading()}>
            <Switch>
              <OrganizationRoute path="/dashboard" name="Organization" component={OrganizationLayout} />
              <AdminstratorRoute path="/admin" name="Admin" component={AdminLayout} />
              
              <Route path="/" name="Home" component={FrontEndLayout} />
              
              
            </Switch>
          </React.Suspense>
      </Router>
    </AuthProvider>
    );
  }
}

const AdminstratorRoute = ({ component, ...rest }) => {
  const {currentUser} = useContext(AuthContext);
  return (
    <Route {...rest} render={routeProps => {

      return (!!userInfoOnStateChange || !!currentUser) && commonService.getLocalStorageValue('role') === "admin" && commonService.getLocalStorageValue('uid') !== "" ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};
const OrganizationRoute = ({ component, ...rest }) => {
 
  const {currentUser} = useContext(AuthContext);
  return (
    <Route {...rest} render={routeProps => {     
      return (!!userInfoOnStateChange || !!currentUser) && commonService.getLocalStorageValue('role') === "user" && commonService.getLocalStorageValue('uid') !== ""  ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}
export default App;
