import React, { useEffect, useState } from "react";
import firebase from './core/Firebase/Firebase';
import commonService from './core/services/commonService';
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  

  useEffect(() => {
    
    firebase.auth().onAuthStateChanged((user) => {
      if(user){

        
        if(user.emailVerified) {
          firebase.firestore().collection(`users`).doc(user.uid).onSnapshot(snapshot => {
            const dbUser = snapshot.data();
            
            if(dbUser) {
            
            
              // merge auth and db user
              user = {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                ...dbUser,
              };
              let userInfo = dbUser;
              let stripe_customer_id = userInfo.stripe_customer_id || "";
              let customer_info = { displayName: `${userInfo.firstName} ${userInfo.lastName}`, email: userInfo.email };
              commonService.setLocalStorageValue('stripe_customer_id', stripe_customer_id);
              commonService.setLocalStorageValue('customer_info', customer_info);
              let role = userInfo.role || "user";
              commonService.setLocalStorageValue('role', role);
              userInfo.isSubscribe = userInfo.isSubscribe !== undefined ? userInfo.isSubscribe : false;
              userInfo.isTrial = userInfo.isTrial !== undefined ? userInfo.isTrial : false;
              userInfo.currentSubscriptionPlan = userInfo.planType !== undefined ? userInfo.planType : "";
              if(userInfo.isSubscribe) {
                let settingsInfo = userInfo.appSettings || {};
                

                commonService.setLocalStorageValue('settingsInfo', settingsInfo);
                
              }
              commonService.setLocalStorageValue('isSubscriber', userInfo.isSubscribe);
              commonService.setLocalStorageValue('currentSubscriptionPlan', userInfo.currentSubscriptionPlan);
              commonService.setLocalStorageValue('isTrial', userInfo.isTrial);
              setCurrentUser(user);
            }
            else
              localStorage.clear();
          });
        }
        
      }
      else
        setCurrentUser(user);
    });
  }, []);

  /*if(pending){
    return <>Loading...</>
  }*/

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};